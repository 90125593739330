#sercard {
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }
}

#sertext {
  @media (max-width: 800px) {
    margin: 0;
    text-align: justify;
  }
}

#serimg {
  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  img {
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 8px;
  }
}
