#left_footer{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
    padding: 10px;
    padding-left: 30px;
    justify-content: space-evenly;
    /* align-items: ; */
}

#footer_text{
    /* color: blue; */
    width: 60%;
    /* margin-top: 60px; */
    width:'60%';

}

#footer_logo{
    width: 150px;

}

#right_footer{
    width: 50%;
    height: 100%;
    display: flex;
    /* flex-direction: column; */
    justify-content: end;
    align-items: center;
    padding-right: 5%;
    /* border: 1px solid red; */
}
#socials{
    margin-left: 80px;
}



@media screen and (max-width: 768px){
    #footer_text{
        /* color: blue; */
        width: 100%;
        font-size: 13px;

}
#footer_logo{
    width: 90px;
   
}
#left_footer{
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
    padding: 25px;
}
#right_footer{
    width: 40%;
    flex-direction: column;
    justify-content: center;


}
#socials{
    margin-left: 0px;
    display: flex;
}

}