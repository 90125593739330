#carousel {
  display: flex;
  overflow-x: auto;
  padding: 1em;
  gap: 20px;
  align-items: center;
}

.testimonial_container {
  cursor: pointer;
  transition: transform 0.5s;
  position: relative;
  min-width: 400px;
  height: 550px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 0;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.overlay {
  background: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  color: white;
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  opacity: 0;
  transition: opacity 0.3s;
}

.testimonial_container:hover .overlay {
  opacity: 0.9;
}

.testimonial_container:hover {
  transform: scale(1.05);
}

#expanded-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.expanded-img {
  max-width: 90%;
  max-height: 85%;
  border-radius: 10px;
}

#expanded-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  color: white;
  text-align: center;
}
