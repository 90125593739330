.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 12px; /* Adjust as needed */
  padding: 0 12px;
}

.gallery .pics {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.gallery .pics:hover:before {
  content: attr(data-title); /* Use data-title attribute */
  position: absolute;
  top: 80%; /* Position at the bottom */
  left: 50%;
  transform: translateX(-50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.gallery .pics:hover {
  filter: brightness(80%);
}

@media (max-width: 991px) {
  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media (max-width: 480px) {
  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: 80%;
  max-width: 80%;
  height: 100%;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px;
  margin: 0 auto;
  object-fit: contain;
}

.model.open svg {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.4s);
  color: white;
  cursor: pointer;
}
