.container {
  display: flex;
  height: 85vh;
  justify-content: space-between;
  padding: 45px;
}

.text {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  color: #008e87;
  font-size: 40px;
  font-weight: bolder;
  letter-spacing: 3px;
}

.name {
  font-size: 40px;
  font-weight: bold;
}

.subname {
  font-size: 30px;
  font-weight: bold;
}

.image {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image img {
  max-width: 100%;
  max-height: 80vh;
  object-fit: cover;
}
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");
.sub {
  font-family: "DM Serif Display", serif;
  font-weight: bold;
  font-size: 65px;
  color: #008e87;
  text-transform: uppercase;
  line-height: 1;
}

@media (max-width: 1100px) {
  .container {
    height: 90vh;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  .text {
    width: 100%;
    height: 50%;
    align-items: center;
  }
  .title {
    font-size: 26px;
    letter-spacing: 1px;
    letter-spacing: normal;
  }
  .sub {
    font-size: 40px;
  }
  .subt {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .designation {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .name {
    font-size: 30px;
  }
  .subname {
    font-size: 20px;
  }

  .image {
    width: min(390px, 80%);
    padding: 10px;
  }
}

.services {
  /* border: 1px solid red; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.card {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  font-size: 40px;
  font-weight: bolder;
}

.about_us {
  padding: 10px;
  /* border: 1px solid black; */
  border-radius: 10px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner {
  /* padding: 15px; */
  /* border: 1px solid black; */
  border-radius: 10px;
  width: 90%;
}

@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&family=Orbitron&family=Oxygen:wght@300;400;700&display=swap");
.inner {
  font-family: "Oxygen", sans-serif;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.5;
  text-align: center;
}
